import { configStore } from "@/store/configStore";
import {computed, defineComponent, ref} from 'vue'
import { mapState } from 'pinia';
export default defineComponent({
  name: "vigilab_cm_dialog",
  props:{
    heightDialog : {
      type: String
    },
    otheDialog: {
      type: Boolean,
      default: false
    },
    statusDialog: {
      type: Boolean,
      default: false
     }
  },
  computed:{
    
  },
  setup(props) {
    const cfStore = configStore()
   /*  const statusDialog = cfStore.statusDialog */
    console.log(props.statusDialog)
    
    return {
    }
  },
})