<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { vigilab_cm_alertglobal } from '@/components/index'
import { configStore } from "@/store/configStore";
import Cookies from 'js-cookie';
import { mapActions } from 'pinia';
import socket from '@/socket/socketManager' 
export default {
  components: {
    vigilab_cm_alertglobal
  },
  watch: {
  },
  name: 'App',
  data: () => ({
    //
  }),
  mounted() {
    /* socket.connectSocket() */
    setInterval(() => {
      const cookie = Cookies.get('status.auth.cookie')
      console.log("contando", location.pathname)
      
      if (!cookie && location.pathname != "/") {
        console.log("entrooo")
        this.$router.push({ name: 'loginView' })
        
      }
    }, 1000)
  },
  methods: {
    ...mapActions(configStore, ["showAlertPage"]),
  }
};
</script>
