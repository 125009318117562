import { mapActions, mapState } from 'pinia';
import {configStore} from "@/store/configStore"
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: "vigilab_cm_alertglobal",
  props: {
    dialogOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
    }
  },
  mounted(){
  },
  watch: {
    showAlert(event){
      console.log(event)
      this.dialog = event
    },
    dialog(event){
      console.log(event)
      if(event == false){
        this.noShowAlert()
      }
    }
  },
  methods: {
    ...mapActions(configStore, ["noShowAlert"]),
    finish(){
      console.log("finish")
    },
    statusAlert(event: Boolean){
      console.log("aquiiiiiiiiiiiiii", event)
      return false
    }
  },
  computed: {
    ...mapState(configStore, ['showAlert', 'titleAlert', 'messageAlert']),
  },
})