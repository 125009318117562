import { configStore } from "@/store/configStore";
import {computed, defineComponent, ref} from 'vue'
import { mapState } from 'pinia';
export default defineComponent({
  name: "covid_cm_dialog",
  props:{
    heightDialog : {
      type: String
    },
    otheDialog: {
      type: Boolean,
      default: false
    },
    titleStatus: {
      type: Boolean,
      default: true
    }
  },
  computed:{
    
  },
  setup() {
    const cfStore = configStore()
   /*  const statusDialog = cfStore.statusDialog */
    const statusDialog = computed(()=> cfStore.statusDialog)
    console.log(statusDialog.value)
    return {
      statusDialog
    }
  },
})