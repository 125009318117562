import { render, staticRenderFns } from "./covid_cm_dialog.vue?vue&type=template&id=6ff0cd22"
import script from "./covid_cm_dialog.ts?vue&type=script&lang=ts&external"
export * from "./covid_cm_dialog.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports