import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Cookies from 'js-cookie'
import { configStore } from '@/store/configStore'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'loginView',
    component:  () => import('../views/auth/vigilab_vw_login.vue'),
    meta: {
      requireAuth: false
    },
  },
  {
    path: '/',
    name: 'layoutView',
    redirect: '/dashboard',
    component:  () => import('../layouts/layouts.vue'),
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'covid/dashboard',
        component: () => import('../views/dashboard/covid_vw_dashboard.vue'),
      },
          // ------------------------ DATA antcovid19 --------------------------------------
    {
      path: '/data/antcovid19/uploadData',
      name: 'covid/data/antcovid19/uploadData',
      component: () => import('../views/data/antcovid19/upload/covid_vw_upload_data.vue'),
    },
    {
      path: '/data/antcovid19/reports',
      name: 'mapView/data/antcovid19/reports',
      component: () => import('../views/data/antcovid19/reports/covid_vw_reports.vue')
    },
    {
      path: '/data/antcovid19/zip',
      name: 'mapView/data/antcovid19/zip',
      component: () => import('../views/data/antcovid19/downloadZip/covid_vw_zip.vue')
    },
    {
      path: '/data/antcovid19/patient',
      name: 'mapView/data/antcovid19/patient',
      component: () => import('../views/data/antcovid19/patients/covid_vw_patients_data.vue')
    },
    // ------------------------ USERS --------------------------------------]
    {
      path: '/users',
      name: 'mapView/users',
      component: () => import('../views/users/cc_vw_users.vue')
    }
    ]
  },
/*   {
    path: '/map',
    name: 'mapView',
    component: () => import('../views/maps/vigilab_vw_map.vue'),
    meta: {
      requireAuth: true,
    }
  } */
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const rutaAuth = to.matched.some(record => record.meta.requireAuth)
  const jwt = localStorage.getItem("asoclinic.auth.token")
  console.log("cache",localStorage.getItem("asoclinic.auth.token"))
  const cookie = Cookies.get('status.auth.cookie')
  console.log(cookie)
  if(!cookie && jwt){
    localStorage.removeItem("asoclinic.auth.token")
    next({ name: 'loginView'})
    /* const cfStore = configStore() */
    /* cfStore.showAlertPage("¡Alerta!", "La sesion ha caducado") */
  }else
  if (rutaAuth && jwt == null) {
    next({ name: 'loginView'})
  } else {
    next()
  }
})
export default router
