import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import vuetify from './plugins/vuetify'
/* import socket from '@/socket/socketManager' */
Vue.config.productionTip = false
/* socket.connectSocket() */
const pinia = createPinia()

const jwt = localStorage.getItem("asoclinic.auth.token")
if (jwt) {
  const jwtPayload = JSON.parse(window.atob(jwt.split('.')[1]))
  console.log("jwtPayload", jwtPayload.exp);
}
Vue.use(PiniaVuePlugin)
new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')
