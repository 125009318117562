
import { defineStore } from "pinia"
import Amplify, { API, Auth } from "aws-amplify";
import cache from '@aws-amplify/cache';
export const configStore = defineStore('configStore', {
    state: () =>({
        showAlert:false,
        titleAlert: "",
        messageAlert: "",
        statusDialog: false,
        viewInfo: false,
        idSocket: ""
    }),

     actions:{
        setViewInfo(viewInfo: boolean){
            this.viewInfo = viewInfo
        },
        showAlertPage(title: string, message: string){
            this.titleAlert = title
            this.messageAlert = message
            this.showAlert = true
        },
        noShowAlert(){
            this.titleAlert = ""
            this.messageAlert = ""
            this.showAlert = false
        },
        showDialog(statusDialog: boolean){
            this.statusDialog = statusDialog
        },
        setIdSocket(idSocket:string){
            this.idSocket = idSocket
        }
     }

})