import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
          customProperties: true,
        },
      themes: {
        light: {
          primary: '#710000',
          secondary: '#4a4e57',
          layoutColor: "#fff",
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#f5f5f5',
          success: '#4CAF50',
          warning: '#FFC107'
        },
        dark: {
            primary: '#5067bf',
            secondary: '#4a4e57',
            layoutColor: "#9155FD",
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#f5f5f5',
            success: '#4CAF50',
            warning: '#FFC107'
        },
      },
    }
});
